// This file is automatically compiled by Webpack, along with any src files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
// finally giving turbolinks a try...
// https://github.com/turbolinks/turbolinks
require("turbolinks").start()
require('@rails/activestorage').start()
require('channels')
// when Bootstrap5 comes out jQuery will die...
require('jquery')
require('bootstrap/dist/js/bootstrap')
window.$ = $

require('datatables.net')(window, $)
require('datatables.net-select')(window, $)

// my own javascript code
require("custom/stuff")

import '../stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    //$('.bs-component [data-toggle="popover"]').popover();
    //$('.bs-component [data-toggle="tooltip"]').tooltip();
})
